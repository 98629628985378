import React from 'react';
import { graphql } from 'gatsby';
import { RiverNorthLocationPageQuery } from '../../../graphql-types';
import LocationTemplate from '../../components/locations/LocationTemplate';

export const pageQuery = graphql`
  query RiverNorthLocationPage {
    allContentfulPagesLocationTemplate(
      filter: { pageId: { eq: "location_river-north" } }
    ) {
      nodes {
        locationName
        locationRegion
        seoTitle
        seoDescription
        isComingSoon
        displayAppointmentsCalendar
        displayOfferBanner
        offerBannerText {
          offerBannerText
        }
        couponCode
        bannerSubtitle {
          bannerSubtitle
        }
        bannerButtonText
        openingDate
        addressLine1
        addressLine2
        locationCoordinates {
          lat
          lon
        }
        phoneNumber
        emailAddress
        hoursLine1
        hoursLine2
        hoursLine3
        descriptionText1 {
          descriptionText1
        }
        descriptionText2 {
          descriptionText2
        }
        transportationTrain {
          transportationTrain
        }
        transportationBus {
          transportationBus
        }
        parking {
          parking
        }
        servicesList {
          services {
            name
            link
          }
        }
        concernsList {
          concerns {
            name
            link
          }
        }
        locationTeam {
          name
          position {
            positionName
          }
          accreditations
          urlSlug
          avatar {
            fluid(maxWidth: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
        imagesVertical {
          description
          fixed(quality: 100, width: 400, height: 640) {
            ...GatsbyContentfulFixed_withWebp
          }
          fluid(quality: 100, maxWidth: 312, maxHeight: 312) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        imagesHorizontal {
          description
          fixed(quality: 100, width: 1000, height: 640) {
            ...GatsbyContentfulFixed_withWebp
          }
          fluid(quality: 100, maxWidth: 312, maxHeight: 312) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;

interface Props {
  data: RiverNorthLocationPageQuery;
}

function RiverNorth({ data }: Props) {
  const {
    locationName,
    locationRegion,
    seoTitle,
    seoDescription,
    isComingSoon,
    displayAppointmentsCalendar,
    displayOfferBanner,
    offerBannerText,
    couponCode,
    bannerSubtitle,
    bannerButtonText,
    openingDate,
    addressLine1,
    addressLine2,
    locationCoordinates,
    phoneNumber,
    emailAddress,
    hoursLine1,
    hoursLine2,
    hoursLine3,
    descriptionText1,
    descriptionText2,
    servicesList,
    concernsList,
    locationTeam,
    imagesVertical,
    imagesHorizontal,
  } = data.allContentfulPagesLocationTemplate.nodes[0];
  return (
    <LocationTemplate
      seo={{ title: seoTitle, description: seoDescription }}
      offerBanner={{
        display: displayOfferBanner,
        text: offerBannerText.offerBannerText,
        couponCode,
      }}
      carouselDesktop={{
        images: [
          {
            fixed: imagesVertical[0].fixed,
            altText: imagesVertical[0].description,
          },
          {
            fixed: imagesHorizontal[0].fixed,
            altText: imagesHorizontal[0].description,
          },
          {
            fixed: imagesVertical[1].fixed,
            altText: imagesVertical[1].description,
          },
          {
            fixed: imagesHorizontal[1].fixed,
            altText: imagesHorizontal[1].description,
          },
          {
            fixed: imagesVertical[2].fixed,
            altText: imagesVertical[2].description,
          },
          {
            fixed: imagesHorizontal[2].fixed,
            altText: imagesHorizontal[2].description,
          },
          {
            fixed: imagesVertical[3].fixed,
            altText: imagesVertical[3].description,
          },
          {
            fixed: imagesHorizontal[3].fixed,
            altText: imagesHorizontal[3].description,
          },
        ],
      }}
      carouselMobile={{
        images: [
          {
            fluid: imagesVertical[0].fluid,
            altText: imagesVertical[0].description,
          },
          {
            fluid: imagesHorizontal[0].fluid,
            altText: imagesHorizontal[0].description,
          },
          {
            fluid: imagesVertical[1].fluid,
            altText: imagesVertical[1].description,
          },
          {
            fluid: imagesHorizontal[1].fluid,
            altText: imagesHorizontal[1].description,
          },
          {
            fluid: imagesVertical[2].fluid,
            altText: imagesVertical[2].description,
          },
          {
            fluid: imagesHorizontal[2].fluid,
            altText: imagesHorizontal[2].description,
          },
          {
            fluid: imagesVertical[3].fluid,
            altText: imagesVertical[3].description,
          },
          {
            fluid: imagesHorizontal[3].fluid,
            altText: imagesHorizontal[3].description,
          },
        ],
      }}
      location={{
        name: locationName,
        region: 'chicago',
        isComingSoon,
        address1: addressLine1,
        address2: addressLine2,
        lat: locationCoordinates.lat,
        lon: locationCoordinates.lon,
        phoneNumber,
        email: emailAddress,
        hours1: hoursLine1,
        hours2: hoursLine2,
        hours3: hoursLine3,
        openingDate,
        descriptionText1: descriptionText1.descriptionText1,
        descriptionText2: descriptionText2.descriptionText2,
        services: servicesList.services,
        concerns: concernsList.concerns,
        team: locationTeam,
      }}
      emailCapture={{
        location: 'river-north',
        subtitleForm: bannerSubtitle.bannerSubtitle,
        textButton: bannerButtonText,
      }}
      appointments={{
        locationId: 17,
        displayCalendar: displayAppointmentsCalendar,
        openingDate,
      }}
    />
  );
}

export default RiverNorth;
